import React, { useEffect, useState } from "react";
import "./Form.scss";
import { Box, Button } from "@mui/material";
import { useGoogleAccountLoginMutation } from "../../redux/services/usersApiSlice";
import { setUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import "react-phone-number-input/style.css";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";

const Form = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [isLoginFromActive, setIsLoginFromActive] = useState(true);

  const [addUser, { data: user, isSuccess }] = useGoogleAccountLoginMutation();

  const login = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${response.access_token}` },
        })
        .then((res) => {
          const userGoogleAccount = res.data;
          addUser({
            username: userGoogleAccount.name || userGoogleAccount.email,
            email: userGoogleAccount.email,
            imageUrl: userGoogleAccount.picture,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
      setOpen(false);
    }
  }, [isSuccess, user, dispatch, setOpen]);

  return (
    <div>
      <Button
        variant="outlined"
        size="large"
        startIcon={<FcGoogle />}
        fullWidth
        onClick={login}
      >
        Continue with Google
      </Button>

      <Box sx={{ textAlign: "center", p: 2 }}>OR</Box>

      {isLoginFromActive ? (
        <Login setOpen={setOpen} setIsLoginFromActive={setIsLoginFromActive} />
      ) : (
        <Signup setOpen={setOpen} setIsLoginFromActive={setIsLoginFromActive} />
      )}
    </div>
  );
};

export default Form;

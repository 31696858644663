import React from "react";
import "./PopularResidence.scss";
import { Link } from "react-router-dom";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import PopularResidenceCards from "../PopularResidenceCards/PopularResidenceCards";
import { useGetProjectsQuery } from "../../redux/services/projectsApiSlice";

const PopularResidence = ({ title }) => {
  const { data: projects, isSuccess } = useGetProjectsQuery({ take: 10 });

  return (
    <>
      {isSuccess && (
        <section id="popular-section">
          <h2 className="popular-title">{title}</h2>

          <div className="show-more-wrapper">
            <Link to="/projects" className="show-more-btn">
              Show more <LiaLongArrowAltRightSolid />
            </Link>
          </div>

          <PopularResidenceCards projects={projects} />
        </section>
      )}
    </>
  );
};

export default PopularResidence;

import React from "react";
import "./SearchBox.scss";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useGetTypesQuery } from "../../redux/services/typesApiSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../redux/searchSlice";

const SearchBox = () => {
  const dispatch = useDispatch();
  const { data: types } = useGetTypesQuery();

  const searchQuery = useSelector((state) => state.search.searchQuery);
  const changeHandler = (event) => {
    const { name, value } = event.target;
    dispatch(
      setSearchQuery({
        ...searchQuery,
        [name]: value,
      })
    );
  };

  let params = "";
  if (searchQuery.type) {
    params += `type=${searchQuery.type}&`;
  }
  if (searchQuery.area) {
    params += `area=${searchQuery.area}&`;
  }
  if (searchQuery.bedrooms) {
    params += `bedrooms=${searchQuery.bedrooms}&`;
  }

  return (
    <>
      <div className="search-box-container">
        <div className="box select-location-wrapper">
          <FormControl fullWidth>
            <InputLabel id="type-simple-select-label">Type</InputLabel>
            <Select
              labelId="type-simple-select-label"
              id="type-simple-select"
              name="type"
              value={searchQuery?.type}
              label="Type"
              onChange={changeHandler}
              sx={{ borderRadius: "2rem" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {types?.map((type) => (
                <MenuItem value={type.title} key={type.title}>
                  {type.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="box select-type-wrapper">
          <FormControl fullWidth>
            <InputLabel id="beds-simple-select-label">Beds</InputLabel>
            <Select
              labelId="beds-simple-select-label"
              id="beds-simple-select"
              value={searchQuery?.bedrooms}
              label="Beds"
              name="bedrooms"
              onChange={changeHandler}
              sx={{ borderRadius: "2rem" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>+6</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="box select-budget-wrapper">
          <FormControl fullWidth>
            <InputLabel id="area-simple-select-label">Area(sqft)</InputLabel>
            <Select
              labelId="area-simple-select-label"
              id="area-simple-select"
              value={searchQuery?.area}
              label="Area"
              name="area"
              onChange={changeHandler}
              sx={{ borderRadius: "2rem" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1000">1000</MenuItem>
              <MenuItem value="2000">2000</MenuItem>
              <MenuItem value="3000">3000</MenuItem>
              <MenuItem value="4000">4000</MenuItem>
              <MenuItem value="5000">5000</MenuItem>
              <MenuItem value="6000">+6000</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Link to={`/search?${params}`} className="search-box">
          <span className="search-icon">
            <FiSearch />
          </span>
          <span className="search">Search</span>
        </Link>
      </div>
    </>
  );
};

export default SearchBox;

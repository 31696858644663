import { apiSlice } from "../api/apiSlice";

export const salePropsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ take }) => `/properties/sale?take=${take}`,
      providesTags: ["SaleProperties"],
    }),

    getProperty: builder.query({
      query: (id) => `/properties/sale/${id}`,
      providesTags: ["SaleProperties"],
    }),
  }),
});

export const { useGetPropertiesQuery, useGetPropertyQuery } = salePropsApiSlice;

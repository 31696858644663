import { apiSlice } from "../api/apiSlice";

export const projectsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: ({ take }) => ({
        url: `/projects?take=${take}`,
      }),

      providesTags: ["Projects"],
    }),

    getProject: builder.query({
      query: (id) => `projects/${id}`,
      providesTags: ["Projects"],
    }),
  }),
});

export const { useGetProjectsQuery, useGetProjectQuery } = projectsApiSlice;

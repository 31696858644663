import React, { useState } from "react";
import "./PropertyCard.scss";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Skeleton, Box } from "@mui/material";
import { GiBathtub, GiResize } from "react-icons/gi";
import { IoIosBed, IoIosPricetags } from "react-icons/io";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { MdCompare } from "react-icons/md";
import {
  useAddNewFavSaleMutation,
  useGetAllFavSaleByUIDQuery,
  useRemoveFavSaleMutation,
} from "../../redux/services/favoriteApiSlice";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const PropertyCard = ({
  id,
  title,
  country,
  city,
  totalPrice,
  type,
  description,
  images,
  bedrooms,
  area,
  bathrooms,
  views,
}) => {
  const navigate = useNavigate();
  const { user, isLogin } = useSelector((state) => state.user);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: favoriteSales } = useGetAllFavSaleByUIDQuery(user?.id);
  const [addFavSale] = useAddNewFavSaleMutation();
  const [removeFavSale] = useRemoveFavSaleMutation();
  const [isFavorite, setIsFavorite] = useState(
    favoriteSales?.some((property) => property.saleId === id)
  );

  const compareHandler = (e) => {
    e.stopPropagation();
    const propsForCompare =
      JSON.parse(window.localStorage.getItem("propsForCompare")) || [];

    // if there are 3 property the first one will remove
    if (propsForCompare.length >= 3) {
      propsForCompare.shift();
    }

    // if the property already in local storage it won't be added
    if (!propsForCompare?.some((propID) => propID === id)) {
      window.localStorage.setItem(
        "propsForCompare",
        JSON.stringify([...propsForCompare, id])
      );
    }
    navigate(`/compare`);
  };

  const toggleFavorite = (e) => {
    e.stopPropagation();
    if (!isLogin) {
      swal({
        title: "You are not login",
        text: "To adding this property to your favorite you need to sign in first.",
        icon: "warning",
        buttons: "ok",
        timer: 5000,
      });
      return false;
    }

    if (isFavorite) {
      removeFavSale({ userId: user.id, saleId: id });
    } else {
      addFavSale({ userId: user.id, saleId: id });
    }
    setIsFavorite(!isFavorite);
  };

  return (
    <div
      className="card"
      onClick={() => navigate(`/sale-property-details/${id}`)}
    >
      <div className="card__img-box">
        <img
          src={images?.length ? images[0]?.url : ""}
          alt={title}
          loading="lazy"
          className={`${isLoaded ? "loaded" : ""}`}
          onLoad={() => setIsLoaded(true)}
        />

        {!isLoaded && (
          <Box
            width="100%"
            height="100%"
            sx={{ aspectRatio: "16/9", position: "absolute", top: 0 }}
          >
            <Skeleton height="100%" />
          </Box>
        )}

        <div className="actions">
          {isFavorite ? (
            <FaHeart title="remove from favorite" onClick={toggleFavorite} />
          ) : (
            <FaRegHeart title="add to favorite" onClick={toggleFavorite} />
          )}

          <MdCompare title="go to compare" onClick={compareHandler} />
        </div>
      </div>

      <div className="card_content">
        <h2 className="property-title">{title}</h2>

        <div className="card_details">
          <div className="detail">
            <IoIosBed /> {bedrooms} Bed
          </div>
          <div className="detail">
            <GiResize /> {area} sqft
          </div>
          <div className="detail">
            <GiBathtub /> {bathrooms} Bath
          </div>
          <div className="detail">
            <HiOutlineLocationMarker />
            {city}, {country}
          </div>
          {!!totalPrice && (
            <div className="detail">
              <IoIosPricetags />
              AED {Number(totalPrice).toLocaleString()}
            </div>
          )}
        </div>

        <div className="property-view">
          {views?.map((view) => view).join(" | ")}
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;

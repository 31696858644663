import { apiSlice } from "../api/apiSlice";

const articlesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllArticles: builder.query({
      query: () => `articles`,
      providesTags: ["Articles"],
    }),

    getArticle: builder.query({
      query: (id) => `articles/${id}`,
      providesTags: (result, error, id) => [{ type: "Articles", id }],
    }),
  }),
});

export const { useGetAllArticlesQuery, useGetArticleQuery } = articlesApiSlice;

import React from "react";
import "./ProjectDetails.scss";
import { useParams } from "react-router-dom";
import Gallery from "../../components/Gallery/Gallery";
import { CiLocationOn } from "react-icons/ci";
import CallToAction from "../../components/CallToAction/CallToAction";
import Location from "../../components/Location/Location";
import SimilarPropertiesCard from "../../components/SimilarPropertiesCard/SimilarPropertiesCard";
import BuyDetails from "../../components/BuyDetails/BuyDetails";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper/modules";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { Skeleton, Box } from "@mui/material";
import { useGetProjectQuery } from "../../redux/services/projectsApiSlice";

const ProjectDetails = () => {
  const { projectID } = useParams();
  const { data: project, isLoading: isProjectLoading } =
    useGetProjectQuery(projectID);

  return (
    <div id="propertyDetails-page">
      <div className="container-fluid">
        <div className="container">
          <header className="header">
            {isProjectLoading ? (
              <Box sx={{ width: "100%", height: "400px" }}>
                <Skeleton height="100%" />
              </Box>
            ) : (
              <Gallery images={project?.images} />
            )}
          </header>

          <main>
            <section className="property-name">
              <div className="property-title">
                {project?.title || "Property Title"}
              </div>
              <div className="property-address">
                <CiLocationOn />{" "}
                {isProjectLoading ? (
                  <Box sx={{ width: "300px" }}>
                    <Skeleton />
                  </Box>
                ) : (
                  project?.location
                )}
                {/* 1.1Km Palm Jebel Ali No.47 Muju Muju */}
              </div>
            </section>

            <section className="property-description">
              {isProjectLoading ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                </Box>
              ) : (
                <p>{project?.description}</p>
              )}
            </section>

            <section className="property-amenities">
              <div className="property-title">Amenities</div>
              <ul className="amenities">
                {project?.amenities.map((amenity) => (
                  <li className="amenities__item" key={amenity}>
                    {amenity}
                  </li>
                ))}
              </ul>
            </section>

            <section className="property-information">
              <div className="property-title">Property Information</div>
              <table className="property-information__table">
                <tbody>
                  <tr>
                    <td>
                      <span className="key">Country</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.country
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">City</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.city
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="key">Owner</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.owner
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">Completion Date</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.completionDate || "--"
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="key">Category</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.category || "Luxury"
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">Off Plan</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : project?.offPlan ? (
                          "Yes"
                        ) : (
                          "No"
                        )}
                      </span>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <span className="key">Floor</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.floor
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">Unit Number</span>
                      <span className="value">
                        {isProjectLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          project?.unitNo
                        )}
                      </span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </section>

            <section className="property-location">
              <div className="property-title">Location</div>
              <div className="location">
                {isProjectLoading ? (
                  <Box sx={{ width: "100%", height: "400px" }}>
                    <Skeleton height="100%" />
                  </Box>
                ) : (
                  <Location location={project?.mapUrl} />
                )}
              </div>
            </section>

            {/* <section className="similar-properties">
              <div className="property-title">Similar Properties</div>
              <div className="cards-container">
                {!!property?.saleUnits?.length && (
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    loop={true}
                    scrollbar={{
                      hide: false,
                    }}
                    modules={[Autoplay, Scrollbar]}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 120,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 300,
                      },
                    }}
                    lazy={true}
                    className="sim-swiper"
                  >
                    {property.saleUnits.map((property) => (
                      <SwiperSlide key={property.id}>
                        <SimilarPropertiesCard {...property} />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </section> */}

            <section className="property-buy-details">
              <BuyDetails
                price={project?.totalPrice}
                pdfUrl={project?.pdfUrl}
                agent={project?.agent}
                title={project?.title}
              />
            </section>
          </main>

          <CallToAction agent={project?.agent} title={project?.title} />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;

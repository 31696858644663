import Home from "./pages/Home/Home";
import Blog from "./pages/Blog/Blog";
import AboutUs from "./pages/AboutUs/AboutUs";
import Compare from "./pages/Compare/Compare";
import ContactUs from "./pages/ContactUs/ContactUs";
import Interested from "./pages/Interested/Interested";
import MainLayout from "./layouts/MainLayout/MainLayout";
import NotFound404 from "./pages/NotFound404/NotFound404";
import SearchFilters from "./pages/SearchFilters/SearchFilters";
import ProjectDetails from "./pages/ProjectDetails/ProjectDetails";
import SalePropertyDetails from "./pages/SalePropertyDetails/SalePropertyDetails";
import RentPropertyDetails from "./pages/RentPropertyDetails/RentPropertyDetails";
import Projects from "./pages/Projects/Projects";
import Blogs from "./pages/Blogs/Blogs";
import PrivateRoute from "./layouts/PrivateRoute/PrivateRoute";

/* const SalePropertyDetails = lazy(() =>
  import("./pages/sale-property-details/SalePropertyDetails")
);
const ProjectDetails = lazy(() =>
  import("./pages/ProjectDetails/ProjectDetails")
); */
// const SearchFilters = lazy(() => import("./pages/SearchFilters/SearchFilters"));

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { element: <Home />, index: true },
      { path: "/blogs", element: <Blogs /> },
      { path: "/blog/:blogID", element: <Blog /> },
      { path: "/compare", element: <Compare /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/search", element: <SearchFilters /> },
      {
        path: "/interest/:userID",
        element: (
          <PrivateRoute>
            <Interested />
          </PrivateRoute>
        ),
      },
      { path: "/projects", element: <Projects /> },
      { path: "/project-details/:projectID", element: <ProjectDetails /> },
      {
        path: "/sale-property-details/:propertyID",
        element: <SalePropertyDetails />,
      },
      {
        path: "/rent-property-details/:propertyID",
        element: <RentPropertyDetails />,
      },
    ],
  },
  { path: "*", element: <NotFound404 /> },
];

export default routes;

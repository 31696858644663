import React from "react";
import "./Home.scss";

import Header from "../../components/Header/Header";
import PopularResidence from "../../components/PopularResidence/PopularResidence";
import AboutUs from "../../components/AboutUs/AboutUs";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import OurServiceArea from "../../components/OurServiceArea/OurServiceArea";
import OurTeam from "../../components/OurTeam/OurTeam";
import ContactUs from "../../components/ContactUs/ContactUs";

const Home = () => {
  return (
    <>
      <div id="home-page">
        <div className="container-fluid">
          <Header />
          <div className="container">
            <PopularResidence title="Popular Projects" />
            <AboutUs />
            <WhyChooseUs />
            {/* <OurServiceArea /> */}
            {/* <OurTeam /> */}
          </div>
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default Home;

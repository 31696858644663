import { apiSlice } from "../api/apiSlice";

export const agentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgents: builder.query({
      query: () => ({
        url: "/agents",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),

      providesTags: ["Agents"],
    }),

    getAgent: builder.query({
      query: (id) => `/agents/${id}`,

      providesTags: ["Agents"],
    }),
  }),
});

export const { useGetAgentsQuery, useGetAgentQuery } = agentsApiSlice;

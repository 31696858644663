import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  searchQuery: {
    title: "",
    area: "",
    price: "",
    bedrooms: "",
    bathrooms: "",
    type: "",
    // Add more fields as needed
  },
  filteredProperties: [],
  isLoading: false,
  isError: false,
  error: "",
};

export const fetchFilteredProperties = createAsyncThunk(
  "properties/filtered",
  async ({ title, area, price, bedrooms, bathrooms, type, location }) => {
    let params = "";
    if (title) {
      params += `title=${title}&`;
    }
    if (type && type !== "-1") {
      params += `type=${type}&`;
    }
    if (area) {
      params += `area=${area}&`;
    }
    if (price) {
      params += `price=${price}&`;
    }
    if (location && location !== "-1") {
      params += `location=${location}&`;
    }
    if (bedrooms && bedrooms !== "-1") {
      params += `bedrooms=${bedrooms}&`;
    }
    if (bathrooms && bathrooms !== "-1") {
      params += `bathrooms=${bathrooms}&`;
    }

    let url = "https://server.dejavureve.com/api/properties/sale";
    if (params) {
      url += `/search?${params}`;
    }

    return await axios.get(url).then((res) => res.data);
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    resetSearchQuery: (state) => {
      for (const key in state.searchQuery) {
        state.searchQuery[key] = "";
      }
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredProperties.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
        state.error = "";
      })
      .addCase(fetchFilteredProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filteredProperties = action.payload;
      })
      .addCase(fetchFilteredProperties.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
    /*  [fetchFilteredProperties.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchFilteredProperties.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.filteredProperties = action.payload;
    },
    [fetchFilteredProperties.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.error.message;
    }, */
  },
});

export default searchSlice.reducer;
export const { setSearchQuery, resetSearchQuery } = searchSlice.actions;

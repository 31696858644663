import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "./PopularResidenceCards.scss";
import PopularResidenceCard from "../PopularResidenceCard/PopularResidenceCard";
import { Link } from "react-router-dom";

const PopularResidenceCards = ({projects}) => {

  const siwperSlides =
    projects?.length &&
    projects.map((project) => (
      <SwiperSlide key={project.id}>
        <Link to={`/project-details/${project.id}`}>
          <PopularResidenceCard {...project} />
        </Link>
      </SwiperSlide>
    ));

  return (
    <div className="cards-wrapper">
      {!!projects?.length && (
        <Swiper
          slidesPerView={1}
          spaceBetween={35}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {siwperSlides}
        </Swiper>
      )}
    </div>
  );
};

export default PopularResidenceCards;

import React from "react";
import "./BuyDetails.scss";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import { Skeleton, Box } from "@mui/material";

const BuyDetails = ({ price, pdfUrl, agent, title }) => {
  return (
    <div className="buy-details">
      {price && (
        <div className="price-container">
          Total Price:
          <div className="price">
            AED{" "}
            {price ? (
              Number(price).toLocaleString()
            ) : (
              <Skeleton sx={{ display: "inline-block" }} width={100} />
            )}
          </div>
        </div>
      )}

      <p className="description">
        For more information and knowledge for installment payment, please
        &nbsp;
        <a href={pdfUrl}>download PDF</a>
        &nbsp; or contact us.
      </p>

      <div className="agent-information">
        {agent ? (
          <img src={agent?.imageUrl} alt={agent?.name} loading="lazy" />
        ) : (
          <Box sx={{ width: "70px", height: "70px" }}>
            <Skeleton variant="circular" height="100%" />
          </Box>
        )}
        <div className="information">
          <div className="name">{agent ? agent?.name : <Skeleton />}</div>
          <div className="contact">
            <a href={`tel:${agent?.phone}`} title="Phone Number">
              <CallIcon />
            </a>
            <a
              href={`https://api.whatsapp.com/send/?phone=${agent?.whatsApp}&text=I am interested to know more about this property: ${title}`}
              target="_blank"
              rel="noreferrer"
              title="WhatsApp"
            >
              <WhatsAppIcon />
            </a>
            <a href={`mailto:${agent?.email}`} title="Mail">
              <MailOutlineIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyDetails;

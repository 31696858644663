import { apiSlice } from "../api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: ["User"],
    }),

    googleAccountLogin: builder.mutation({
      query: (user) => ({
        url: "/users/google",
        method: "POST",
        body: user,
      }),
    }),

    registerUser: builder.mutation({
      query: (user) => ({
        url: "/users",
        method: "POST",
        body: user,
      }),
    }),

    loginUser: builder.mutation({
      query: (user) => ({
        url: "/users/sign-in",
        method: "POST",
        body: user,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGoogleAccountLoginMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
} = usersApiSlice;

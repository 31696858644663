import { apiSlice } from "../api/apiSlice";

export const typesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTypes: builder.query({
      query: () => ({
        url: "/types",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),

      providesTags: ["Types"],
    }),
  }),
});

export const { useGetTypesQuery } = typesApiSlice;

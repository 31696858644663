import React from "react";
import "./AboutUs.scss";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      {/* about us  */}
      <div id="about">
        <div className="container">
          {/* section contact */}
          <section className="contact m-b">
            <div className="contact__head title-style ">
              <h1>We’re on a mission</h1>
            </div>
            <div className="contact__description text-style">
              <p>
                At Deja Vu Reve, our mission is to redefine the art of living
                through visionary real estate solutions. We strive to create
                iconic spaces that seamlessly blend innovation, luxury, and
                sustainability. Committed to unparalleled craftsmanship and
                client satisfaction, we aim to elevate lifestyles, leaving an
                indelible mark on the landscape of premium living. Our mission
                is to inspire and exceed expectations, setting a benchmark for
                excellence in the realm of real estate development.
              </p>
            </div>
            <Link to="/contact-us" className="btn-style contact__btn text-style">
              Contact Us
            </Link>
          </section>

          {/* section information-us */}
          <section className="information-us m-b">
            <div className="information-us__img">
              <img src="/images/our-mission.webp"/>
            </div>
            <div className="information-us__info">
              <div className="wrapper">
                <div className="item">
                  <p className="item__value title-style">$8.5M+</p>
                  <p className="item__title text-style">Property Value</p>
                </div>
                <div className="item">
                  <p className="item__value title-style">99%</p>
                  <p className="item__title text-style">
                    Customer Satisfaction
                  </p>
                </div>
                <div className="item">
                  <p className="item__value title-style">300</p>
                  <p className="item__title text-style">Team Member</p>
                </div>
                <div className="item">
                  <p className="item__value title-style">10+</p>
                  <p className="item__title text-style">Work Experience</p>
                </div>
              </div>
            </div>
          </section>

          {/* section about-us-details */}
          <section className="about-us-detail m-b">
            <div className="about-us-detail__img">
              <img src="/images/about-us.webp" alt="about-us" />
            </div>
            <div className="about-us-detail__text">
              <h2 className="title-style">Our Founding Story</h2>
              <p className="text-style">
                Inspired by the dynamic spirit of UNITED ARAB EMIRATES , Deja Vu
                Reve emerged from a collective passion for transforming real
                estate into unparalleled experiences. Our founders, driven by a
                vision to create distinctive properties, embarked on a journey
                marked by innovation and unwavering dedication. Through
                meticulous planning and a commitment to excellence, we have
                crafted a brand synonymous with luxury and sophistication,
                setting new standards in the realm of real estate. This is our
                story of turning vision into reality, shaping spaces that
                resonate with the essence of modern living.
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutUs;

import React from "react";
import "./NotFound404.scss";
import { Link } from "react-router-dom";

const NotFound404 = () => {
  return (
    <div id="notFound">
      <div className="container h-100">
        <div className="wrapper">
          <div className="notFound__img">
            <img src="/images/404.webp" alt="404-error"></img>
          </div>
          <div className="notFound__text">
            <h1 className="notFound__text__head">404</h1>
            <p className="notFound__text__title">Something’s missing </p>
            <p className="notFound__text__subtitle">
              This page is missing or you assembled the link incorrectly.
            </p>
            <div className="notFound__text__back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="88"
                height="23"
                viewBox="0 0 88 23"
                fill="none"
              >
                <path
                  d="M0.94293 10.4331C0.35371 11.0154 0.348123 11.9652 0.930452 12.5544L10.4201 22.1563C11.0024 22.7456 11.9521 22.7511 12.5414 22.1688C13.1306 21.5865 13.1362 20.6367 12.5538 20.0475L4.11862 11.5125L12.6537 3.07725C13.2429 2.49492 13.2485 1.54519 12.6661 0.95597C12.0838 0.366748 11.1341 0.361162 10.5449 0.943493L0.94293 10.4331ZM87.0088 10.5L2.00616 10L1.98851 13L86.9912 13.5L87.0088 10.5Z"
                  fill="white"
                />
              </svg>
              <Link to={-1}>GO BACK</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound404;

import React from "react";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <>
      <section id="about-us">
        <div className="about-us_img"></div>
        <div className="about-us_text">
          <div className="line-title">
            <h3>About Us</h3>
            <div className="bottom-line"></div>
          </div>
        </div>
        <div className="text">
          <p>
            Welcome to Deja Vu Reve Properties! With a wealth of experience in
            the real estate industry, we proud stand as your trusted partner in
            finding your dream property. Our strong connections with global
            property offices ensure that you have access to a world of
            opportunities. Whether you’re seeking a cozy home or a lucrative
            investment, we are dedicated to guiding you every step of the way.
            Explore a new realm of real estate with Deja Vu Reve Properties
            today.
          </p>
        </div>
      </section>
    </>
  );
};

export default AboutUs;

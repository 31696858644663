import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilteredProperties,
  resetSearchQuery,
  setSearchQuery,
} from "../../redux/searchSlice";
import { useGetTypesQuery } from "../../redux/services/typesApiSlice";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./SearchForm.scss";

const SearchForm = ({
  searchHandler,
  sortBy,
  setSortBy,
  sortType,
  setSortType,
}) => {
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const { data: types } = useGetTypesQuery();

  const changeHandler = (event) => {
    const { name, value } = event.target;
    dispatch(
      setSearchQuery({
        ...searchQuery,
        [name]: value,
      })
    );
  };

  const reset = () => {
    dispatch(resetSearchQuery());
  };

  useEffect(() => {
    dispatch(fetchFilteredProperties(searchQuery));
  }, [searchQuery, dispatch]);

  return (
    <form
      action=""
      onSubmit={(e) => {
        e.preventDefault();
        searchHandler();
      }}
    >
      <div className="aside-filters-header">
        <h2 className="aside-filters-title">FILTERS</h2>
        <button className="aside-filter-reset-btn" onClick={reset}>
          Reset
        </button>
      </div>
      <div className="aside-filter-collection mb">
        {/* <h3 className="aside-filters-subtitle">Type</h3> */}
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="title"
            value={searchQuery?.title}
            onChange={changeHandler}
            placeholder="Enter property name"
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Type"
            name="type"
            value={searchQuery?.type || -1}
            onChange={changeHandler}
          >
            <MenuItem value="-1">select one property type</MenuItem>
            {!!types?.length &&
              types.map((type) => (
                <MenuItem value={type.title} key={type.id}>
                  {type.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={}
            label="Location"
            name="location"
            defaultValue="-1"
            onChange={changeHandler}
          >
            <MenuItem value="-1">select one location</MenuItem>
            <MenuItem value="Dubai">Dubai</MenuItem>
            <MenuItem value="Canal">Canal</MenuItem>
            <MenuItem value="Sharjah">Sharjah</MenuItem>
            <MenuItem value="Abu Dhabi">Abu Dhabi</MenuItem>
            <MenuItem value="Palm Jumeirah">Palm Jumeirah</MenuItem>
            <MenuItem value="Palm Jebel Ali">Palm Jebel Ali</MenuItem>
            <MenuItem value="Jabal Ali">Jabal Ali</MenuItem>
          </Select>
        </FormControl> */}

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">Bedrooms</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Bedrooms"
            name="bedrooms"
            value={searchQuery?.bedrooms || -1}
            onChange={changeHandler}
          >
            <MenuItem value="-1">select number of bedrooms</MenuItem>
            <MenuItem value={2}>{2}</MenuItem>
            <MenuItem value={3}>{3}</MenuItem>
            <MenuItem value={4}>{4}</MenuItem>
            <MenuItem value={5}>{5}</MenuItem>
            <MenuItem value={6}>{6}</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">Bathrooms</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Bathrooms"
            name="bathrooms"
            value={searchQuery?.bathrooms || -1}
            onChange={changeHandler}
          >
            <MenuItem value="-1">select number of bathrooms</MenuItem>
            <MenuItem value={2}>{2}</MenuItem>
            <MenuItem value={3}>{3}</MenuItem>
            <MenuItem value={4}>{4}</MenuItem>
            <MenuItem value={5}>{5}</MenuItem>
            <MenuItem value={6}>{6}</MenuItem>
          </Select>
        </FormControl>

        {/* <div className="checkbox-radio-wrapper">
          <input
            type="radio"
            id="apartments"
            name="collections"
            value="Apartments"
            checked
          />
          <label htmlFor="apartments">Apartments </label>
          <span className="total-apartments">(23)</span>
        </div>

        <div className="checkbox-radio-wrapper">
          <input type="radio" id="houses" name="collections" value="Houses" />
          <label htmlFor="houses">Houses </label>
          <span className="total-houses">(67)</span>
        </div> */}
      </div>

      <div className="aside-filter-range mb">
        <h3 className="aside-filters-subtitle">Price Range (AED)</h3>
        <div className="range-box">
          <input
            type="range"
            min="10000000"
            max="90000000000"
            name="price"
            value={searchQuery?.price}
            onChange={changeHandler}
            className="price-range"
            id="priceRange"
          />
        </div>
        <div className="range-details">
          <span>10,000,000</span>
          <span>{Number(searchQuery?.price).toLocaleString()}</span>
          <span>90,000,000,000</span>
        </div>
      </div>

      <div className="aside-filter-range mb">
        <h3 className="aside-filters-subtitle">Area (sqft)</h3>
        <div className="range-box">
          <input
            type="range"
            min="700"
            max="10000"
            name="area"
            value={searchQuery?.area}
            onChange={changeHandler}
            className="area-range"
            id="areaRange"
          />
        </div>
        <div className="range-details">
          <span>700 {/* m<sup className="sup">2</sup> */}</span>
          <span>
            {searchQuery?.area} sqft{/* m<sup className="sup">2</sup> */}
          </span>
          <span>10,000 {/* m<sup className="sup">2</sup> */}</span>
          {/* sup {font-size:xx-small; vertical-align:super;} */}
        </div>
      </div>

      <div className="aside-filters-header">
        <h2 className="aside-filters-title" style={{ marginTop: "1rem" }}>
          SORT
        </h2>
      </div>
      <div className="aside-filter-collection">
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Sort by"
            name="sort"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value="newest">Newest</MenuItem>
            <MenuItem value="bedrooms">Bedrooms</MenuItem>
            <MenuItem value="price">Price</MenuItem>
            <MenuItem value="area">Area</MenuItem>
            <MenuItem value="bathrooms">Bathrooms</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">Sort type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Sort type"
            name="sort"
            value={sortType}
            onChange={(e) => setSortType(e.target.value)}
          >
            <MenuItem value="descending">Descending</MenuItem>
            <MenuItem value="ascending">Ascending</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* <div className="aside-apply-btn-wrapper">
        <button type="submit" className="aside-filter-apply-btn">
          Apply
        </button>
      </div> */}
    </form>
  );
};

export default SearchForm;

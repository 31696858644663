import React from "react";
import "./Blogs.scss";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ArticleCard from "../../components/ArticleCard/ArticleCard";
import { useGetAllArticlesQuery } from "../../redux/services/articlesApiSlice";

const Blogs = () => {
  const { data: articles, isSuccess } = useGetAllArticlesQuery();

  return (
    <div id="blogs-page">
      <header>
        <img src="/images/blogs-header.jpg" alt="blogs-header" />
        <div className="overlay"></div>
      </header>

      <div className="container">
        <main>
          <h2 className="blogs-page-title">Explore Our Blogs</h2>
          {isSuccess && (
            <Grid container spacing={8}>
              {articles?.map((article) => (
                <Grid item xs={12} sm={6} md={4} key={article.id}>
                  <Link to={`/blog/${article.id}`}>
                    <ArticleCard {...article} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </main>
      </div>
    </div>
  );
};

export default Blogs;

import React from "react";
import "./ContactUs.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSendMessagesMutation } from "../../redux/services/messagesApiSlice";

const ContactUs = () => {
  const [sendMessage] = useSendMessagesMutation();

  const schema = yup.object({
    name: yup.string().required("Filling in the full name field is mandatory"),
    phone: yup
      .string()
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number"),
    email: yup
      .string()
      .email("The email format entered is incorrect")
      .required("Filling in the email field is mandatory"),
    text: yup.string().required("Filling in the message field is mandatory"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      phone: null,
      email: "",
      text: "",
    },
    resolver: yupResolver(schema),
  });

  const formSubmitting = (data) => {
    sendMessage(data);
    reset();
  };

  return (
    <div id="contact-us" className="h-100">
      <div className="wrapper">
        {/* box left */}
        <div className="contact-us">
          <h1 className="beige-color">Contact Us</h1>
          {/* social */}
          <div className="social">
            <div className="social__location mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="62"
                height="62"
                viewBox="0 0 62 62"
                fill="none"
              >
                <path
                  d="M31 34.875C29.084 34.875 27.211 34.3068 25.6179 33.2424C24.0248 32.1779 22.7831 30.6649 22.0499 28.8948C21.3167 27.1246 21.1249 25.1768 21.4986 23.2976C21.8724 21.4184 22.7951 19.6922 24.1499 18.3374C25.5047 16.9826 27.2309 16.0599 29.1101 15.6861C30.9893 15.3124 32.9371 15.5042 34.7073 16.2374C36.4774 16.9706 37.9904 18.2123 39.0549 19.8054C40.1193 21.3985 40.6875 23.2715 40.6875 25.1875C40.6844 27.7558 39.6628 30.2181 37.8467 32.0342C36.0306 33.8503 33.5683 34.8719 31 34.875ZM31 19.375C29.8504 19.375 28.7266 19.7159 27.7708 20.3546C26.8149 20.9933 26.0699 21.9011 25.63 22.9632C25.19 24.0253 25.0749 25.194 25.2992 26.3215C25.5235 27.449 26.0771 28.4847 26.8899 29.2976C27.7028 30.1105 28.7385 30.664 29.866 30.8883C30.9936 31.1126 32.1623 30.9975 33.2244 30.5576C34.2864 30.1176 35.1942 29.3726 35.8329 28.4168C36.4716 27.4609 36.8125 26.3371 36.8125 25.1875C36.811 23.6464 36.1981 22.1689 35.1084 21.0791C34.0186 19.9894 32.5411 19.3765 31 19.375Z"
                  fill="#ECC7A1"
                />
                <path
                  d="M31 58.125L14.6553 38.8488C14.4282 38.5594 14.2034 38.2681 13.981 37.975C11.189 34.2972 9.68076 29.805 9.68752 25.1875C9.68752 19.5351 11.9329 14.1142 15.9298 10.1173C19.9267 6.12042 25.3476 3.875 31 3.875C36.6525 3.875 42.0734 6.12042 46.0702 10.1173C50.0671 14.1142 52.3125 19.5351 52.3125 25.1875C52.3193 29.8029 50.8117 34.2931 48.021 37.9692L48.019 37.975C48.019 37.975 47.4378 38.7384 47.3506 38.8411L31 58.125ZM17.0733 35.6403C17.0771 35.6403 17.5266 36.2371 17.6293 36.3649L31 52.1343L44.3881 36.3436C44.4734 36.2371 44.9268 35.6364 44.9287 35.6345C47.2094 32.6297 48.442 28.9599 48.4375 25.1875C48.4375 20.5628 46.6004 16.1275 43.3302 12.8573C40.06 9.58716 35.6247 7.75 31 7.75C26.3753 7.75 21.94 9.58716 18.6698 12.8573C15.3997 16.1275 13.5625 20.5628 13.5625 25.1875C13.5585 28.9622 14.7905 32.6341 17.0733 35.6403Z"
                  fill="#ECC7A1"
                />
              </svg>
              <h4 className="title-style beige-color ">Address</h4>
              <address className="subtitle-style">
                Unit.1723, Level 17, Tamani Art Building Business, Bay Dubai,
                UAE
              </address>
            </div>
            <div className="social__phone mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="57"
                viewBox="0 0 33 57"
                fill="none"
                className="svg-style"
              >
                <path
                  d="M26.6538 0H6.34615C4.66305 0 3.04888 0.682425 1.85875 1.89715C0.668611 3.11187 0 4.75939 0 6.47727V50.5227C0 52.2406 0.668611 53.8881 1.85875 55.1028C3.04888 56.3176 4.66305 57 6.34615 57H26.6538C28.337 57 29.9511 56.3176 31.1413 55.1028C32.3314 53.8881 33 52.2406 33 50.5227V6.47727C33 4.75939 32.3314 3.11187 31.1413 1.89715C29.9511 0.682425 28.337 0 26.6538 0ZM16.5 54.4091C14.3931 54.4091 12.6923 52.6732 12.6923 50.5227C12.6923 48.3723 14.3931 46.6364 16.5 46.6364C18.6069 46.6364 20.3077 48.3723 20.3077 50.5227C20.3077 52.6732 18.6069 54.4091 16.5 54.4091ZM27.9231 44.0455H5.07692V7.77273H27.9231V44.0455Z"
                  fill="#ECC7A1"
                />
              </svg>
              <h4 className="title-style beige-color">Phone</h4>
              <p className="subtitle-style">(+971) 4287 0449</p>
            </div>
            <div className="social__email mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="68"
                height="52"
                viewBox="0 0 68 52"
                fill="none"
                className="svg-style"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M62.3322 0C65.4602 0 68 2.58613 68 5.77893V46.2211C68 49.4104 65.4636 52 62.3322 52H5.6678C2.5398 52 0 49.4139 0 46.2211V5.77893C0 2.5896 2.5364 0 5.6678 0H62.3322ZM24.3712 30.6037L7.6772 47.424H59.7822L43.2684 30.7771L34 37.3187L24.3712 30.6037ZM63.376 16.6123L47.0696 28.1077L63.376 44.5259V16.6123ZM4.6274 16.8237V43.9885L20.5598 27.9344L4.6274 16.8237ZM62.2778 4.57947H5.7732C5.46294 4.56463 5.15885 4.67129 4.9232 4.8776C4.81849 4.97321 4.73765 5.09291 4.68739 5.22675C4.63713 5.3606 4.61889 5.50473 4.6342 5.6472V11.2632L34 31.7408L63.3896 11.0621V5.88987C63.393 5.42533 63.2876 5.08907 63.0768 4.8776C62.9721 4.77376 62.8472 4.69354 62.7101 4.64218C62.5731 4.59081 62.4269 4.56944 62.2812 4.57947H62.2778Z"
                  fill="#ECC7A1"
                />
              </svg>
              <h4 className="title-style beige-color">Email</h4>
              <p className="subtitle-style">info@dejavureve.com</p>
            </div>
          </div>
        </div>
        {/*box right */}
        <div className="contact-us-form">
          <div className="contact-us-form__caption text-uppercase text-style-primery beige-color">
            <p>
              If you got any questions
              <br /> please do not hesitate to send us a massage
            </p>
          </div>
          {/* form */}
          <form id="form" onSubmit={handleSubmit(formSubmitting)}>
            <div className="form__item">
              <label
                htmlFor="fullName"
                className="text-style-primery beige-color"
              >
                Full Name
              </label>
              <input
                type="text"
                placeholder="ex.Simon"
                id="fullName"
                className="input-style"
                {...register("name")}
              />
              <span className="error">
                {errors?.name && errors.name.message}
              </span>
            </div>
            <div className="form__item">
              <label
                htmlFor="LastName"
                className="text-style-primery beige-color"
              >
                Phone Number
              </label>
              <input
                type="text"
                placeholder="+9719365041466"
                id="phoneNumber"
                className="input-style"
                {...register("phone")}
              />
              <span className="error">
                {errors?.phone && errors.phone.message}
              </span>
            </div>
            <div className="form__item">
              <label htmlFor="email" className="text-style-primery beige-color">
                Email Address
              </label>
              <input
                type="email"
                placeholder="example@gmail.com"
                id="email"
                className="input-style"
                {...register("email")}
              />
              <span className="error">
                {errors?.email && errors.email.message}
              </span>
            </div>
            <div className="form__item">
              <label
                htmlFor="message"
                className="text-style-primery beige-color"
              >
                Massage
              </label>
              <textarea
                placeholder="Text ..."
                id="message"
                className="input-style"
                {...register("text")}
              />
              <span className="error">
                {errors?.text && errors.text.message}
              </span>
            </div>
            <br />
            <input
              type="submit"
              id="send"
              value={"Send Massage"}
              className="input-style text-style-primery"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

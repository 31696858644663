import React from "react";
import "./ArticleCard.scss";

const ArticleCard = ({ title, description, images }) => {
  return (
    <div className="article-card">
      <div className="article-card-img-box">
        <img src={images[0]?.url} alt={title} />
      </div>
      <div className="article-card-content-wrapper">
        <h3 className="article-card-content-title" title={title}>
          {title}
        </h3>
        <p className="article-card-content-text">{description}</p>
        {/* <div className="profile-details">
          <div className="img-profile">
            <img src="./images/agent.webp" alt="profile" />
          </div>
          <h4 className="profile-name">Deja Vu Reve</h4>
          <time datetime="2023-28-08">8/28/2023</time>
        </div> */}
        <div className="btn-wrapper">
          <button className="view-more-btn">View More</button>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;

import React from "react";
import "./Projects.scss";
import { Grid } from "@mui/material";
import PopularResidenceCard from "../../components/PopularResidenceCard/PopularResidenceCard";
import { Link } from "react-router-dom";
import { useGetProjectsQuery } from "../../redux/services/projectsApiSlice";

const Projects = () => {
  const { data: projects, isSuccess } = useGetProjectsQuery({ take: 0 });

  return (
    <div id="projects-page">
      <header>
        <img src="./images/projects-header.webp" alt="projects-header" />
      </header>

      <div className="container">
        <main>
          <h2 className="projects-page-title">Popular Projects</h2>
          {isSuccess && (
            <Grid container spacing={8}>
              {projects?.map((project) => (
                <Grid item xs={12} sm={6} md={4} key={project.id}>
                  <Link to={`/project-details/${project.id}`}>
                    <PopularResidenceCard {...project} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </main>
      </div>
    </div>
  );
};

export default Projects;

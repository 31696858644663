import React from "react";
import "./SalePropertyDetails.scss";
import { useParams } from "react-router-dom";
import Gallery from "../../components/Gallery/Gallery";
import { CiLocationOn } from "react-icons/ci";
import { AiFillCar } from "react-icons/ai";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { GiBathtub, GiResize } from "react-icons/gi";
import CallToAction from "../../components/CallToAction/CallToAction";
import Location from "../../components/Location/Location";
import SimilarPropertiesCard from "../../components/SimilarPropertiesCard/SimilarPropertiesCard";
import BuyDetails from "../../components/BuyDetails/BuyDetails";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper/modules";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { Skeleton, Box } from "@mui/material";
import { useGetPropertyQuery } from "../../redux/services/salePropsApiSlice";

const SalePropertyDetails = () => {
  const { propertyID } = useParams();
  const { data: property, isLoading: isPropertyLoading } =
    useGetPropertyQuery(propertyID);

  return (
    <div id="propertyDetails-page">
      <div className="container-fluid">
        <div className="container">
          <header className="header">
            {isPropertyLoading ? (
              <Box sx={{ width: "100%", height: "400px" }}>
                <Skeleton height="100%" />
              </Box>
            ) : (
              <Gallery images={property?.images} />
            )}
          </header>

          <main>
            <section className="property-name">
              <div className="property-title">
                {property?.title || "Property Title"}
              </div>
              <div className="property-address">
                <CiLocationOn />{" "}
                {isPropertyLoading ? (
                  <Box sx={{ width: "300px" }}>
                    <Skeleton />
                  </Box>
                ) : (
                  property?.location
                )}
                {/* 1.1Km Palm Jebel Ali No.47 Muju Muju */}
              </div>
            </section>

            <section className="property-details">
              <div className="detail">
                <BedOutlinedIcon /> {property?.bedrooms} Bed
              </div>
              <div className="detail">
                <GiResize /> {property?.area} sqft
              </div>
              <div className="detail">
                <GiBathtub /> {property?.bathrooms} Bath
              </div>
              <div className="detail">
                <AiFillCar /> {property?.parkingCount} Parking
              </div>
            </section>

            <section className="property-description">
              {isPropertyLoading ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                </Box>
              ) : (
                <p>{property?.description}</p>
              )}
            </section>

            {!!property?.bluePrint && (
              <section className="property-blueprint">
                <div className="property-title">Blueprint</div>
                <img src={property?.bluePrint} alt={property?.title} />
              </section>
            )}

            <section className="property-amenities">
              <div className="property-title">Amenities</div>
              <ul className="amenities">
                {property?.amenities.map((amenity) => (
                  <li className="amenities__item" key={amenity}>
                    {amenity}
                  </li>
                ))}
              </ul>
            </section>

            <section className="property-information">
              <div className="property-title">Property Information</div>
              <table className="property-information__table">
                <tbody>
                  <tr>
                    <td>
                      <span className="key">Country</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.country
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">City</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.city
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="key">Owner</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.owner
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">Completion Date</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.completionDate || "--"
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="key">Type</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.type
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">Category</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.category || "Luxury"
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="key">Floor</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.floor
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="key">Unit Number</span>
                      <span className="value">
                        {isPropertyLoading ? (
                          <Box sx={{ width: "100px" }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          property?.unitNo
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section className="property-location">
              <div className="property-title">Location</div>
              <div className="location">
                {isPropertyLoading ? (
                  <Box sx={{ width: "100%", height: "400px" }}>
                    <Skeleton height="100%" />
                  </Box>
                ) : (
                  <Location location={property?.mapUrl} />
                )}
              </div>
            </section>

            {/* <section className="similar-properties">
              <div className="property-title">Similar Properties</div>
              <div className="cards-container">
                {!!property?.saleUnits?.length && (
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    loop={true}
                    scrollbar={{
                      hide: false,
                    }}
                    modules={[Autoplay, Scrollbar]}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 120,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 300,
                      },
                    }}
                    lazy={true}
                    className="sim-swiper"
                  >
                    {property.saleUnits.map((property) => (
                      <SwiperSlide key={property.id}>
                        <SimilarPropertiesCard {...property} />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </section> */}

            <section className="property-buy-details">
              <BuyDetails
                price={property?.totalPrice}
                pdfUrl={property?.pdfUrl}
                agent={property?.agent}
                title={property?.title}
              />
            </section>
          </main>

          <CallToAction agent={property?.agent} title={property?.title} />
        </div>
      </div>
    </div>
  );
};

export default SalePropertyDetails;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Accordion.scss";

export default function BasicAccordion() {
   const [expanded, setExpanded] = React.useState(false);

   const handleChange = (name) => (event, isExpanded) => {
      setExpanded(isExpanded ? name : false);
   };

   return (
      <div className="ac">
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion1"}
            onChange={handleChange("Accordion1")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Experience and Expertise.png"
                     className="save"
                     alt="icon"
                  />
                  Experience and Expertise
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  With years of experience in the real estate field, our team
                  brings a deep understanding of the market trends and property
                  dynamics, ensuring you make informed decisions.
               </Typography>
            </AccordionDetails>
         </Accordion>
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion2"}
            onChange={handleChange("Accordion2")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Global Network.png"
                     className="key"
                     alt="icon"
                  />
                  Global Network
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  Our strong connections with global property offices give you
                  access to a diverse range of properties across different
                  regions, opening up endless possibilities for your property
                  search.
               </Typography>
            </AccordionDetails>
         </Accordion>
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion3"}
            onChange={handleChange("Accordion3")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Personalized Approach.png"
                     className="easy"
                     alt="icon"
                  />
                  Personalized Approach
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  We understand that each client's needs are unique. That's why
                  we offer personalized solutions tailored to your preferences,
                  making your property journey smooth and enjoyable.
               </Typography>
            </AccordionDetails>
         </Accordion>
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion4"}
            onChange={handleChange("Accordion4")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Transparency.png"
                     className="peyment"
                     alt="icon"
                  />
                  Transparency
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  We believe in transparency and honesty throughout the buying
                  or selling process. You can trust us to provide accurate
                  information, realistic expectations, and clear communication.
               </Typography>
            </AccordionDetails>
         </Accordion>
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion5"}
            onChange={handleChange("Accordion5")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Comprehensive Services.png"
                     className="easy"
                     alt="icon"
                  />
                  Comprehensive Services
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  From property research and viewings to negotiations and
                  paperwork, we provide end-to-end services, saving you time and
                  effort while ensuring a seamless experience.
               </Typography>
            </AccordionDetails>
         </Accordion>
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion6"}
            onChange={handleChange("Accordion6")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Client Satisfaction.png"
                     className="easy"
                     alt="icon"
                  />
                  Client Satisfaction
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  Our track record of satisfied clients speaks for itself. We
                  are committed to going above and beyond to exceed your
                  expectations and make your real estate dreams a reality.
               </Typography>
            </AccordionDetails>
         </Accordion>
         <Accordion
            className="accordion-item"
            expanded={expanded === "Accordion7"}
            onChange={handleChange("Accordion7")}
         >
            <AccordionSummary
               className="header-accordion"
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className="text-header-accordion">
                  <img
                     src="images/Innovation.png"
                     className="easy"
                     alt="icon"
                  />
                  Innovation
               </Typography>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
               <Typography>
                  We stay up-to-date with the latest technological tools and
                  industry advancements, enabling us to provide innovative
                  solutions that streamline your property journey.
               </Typography>
            </AccordionDetails>
         </Accordion>
      </div>
   );
}

import { configureStore } from "@reduxjs/toolkit";
import searchReducers from "./searchSlice";
import userReducers from "./userSlice";
import { apiSlice } from "./api/apiSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    search: searchReducers,
    user: userReducers,
  },
  middleware: (getDefaultMiddlerware) =>
    getDefaultMiddlerware().concat(apiSlice.middleware),
});

export default store;

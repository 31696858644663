import React from "react";
import "./Footer.scss";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { FiFacebook } from "react-icons/fi";
import { LuLinkedin } from "react-icons/lu";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-grid">
          <div className="footer-left-section">
            <h3 className="footer-subtitle">About Us</h3>
            <p>
              Welcome to Deja Vu Reve Properties! With a wealth of experience in
              the real estate industry, we proudly stand as your trusted partner
              in finding your dream property. Our strong connections with global
              property offices ensure that you have access to a world of
              opportunities.
            </p>
          </div>
          <div className="footer-center-section">
            <h3 className="footer-subtitle">Explore</h3>
            <ul className="footer-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/projects">Projects</Link>
              </li>
              <li>
                <Link to="/search">Properties</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
            </ul>
          </div>
          <div className="footer-right-section">
            <h3 className="footer-subtitle">Contact Us</h3>
            <ul className="footer-address-mobile">
              <li>Unit.1723, Level 17,</li>
              {/* <li><a href="tel:+4733378901">(+98)912245835</a></li> */}
              <li>Tamani Art Building Business,</li>
              <li>Dubai United Arab</li>
              <li>Business, Bay Dubai, UAE</li>
            </ul>
            <div className="footer-icon-wrapper">
              <a href="#">
                <BsInstagram />
              </a>
              <a href="mailto:info@dejavureve.com">
                <TfiEmail />
              </a>
              <a href="#">
                <BsWhatsapp />
              </a>
              <a href="#">
                <FiFacebook />
              </a>
              <a href="#">
                <LuLinkedin />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-Copyright">
          <p> &copy; Copyright 2024 , all rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "./Location.scss";

const Location = ({ location }) => {
  return (
    <iframe
      src={location}
      loading="lazy"
      title="map"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default Location;

import React, { useEffect, useState } from "react";
import "./Compare.scss";
import ComparePropertyCard from "../../components/ComparePropertyCard/ComparePropertyCard";
import CompareFeatureCard from "../../components/CompareFeatureCard/CompareFeatureCard";
import CompareInformationCard from "../../components/CompareInformationCard/CompareInformationCard";
import CompareAmenitiesCard from "../../components/CompareAmenitiesCard/CompareAmenitiesCard";
import { useGetPropertiesQuery } from "../../redux/services/salePropsApiSlice";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FiSearch } from "react-icons/fi";
import PropertyCard from "../../components/PropertyCard/PropertyCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "500px",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "2rem",
  boxShadow: 24,
  p: 4,
};

const Compare = () => {
  const { data: properties } = useGetPropertiesQuery({ take: 0 });
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const triggerRerender = () => {
    setRerenderFlag(!rerenderFlag);
  };

  const propertiesID =
    JSON.parse(window.localStorage.getItem("propsForCompare")) || [];

  const propsForCompare = properties?.filter((property) =>
    propertiesID?.includes(property.id)
  );

  // Function to filter data based on search term
  const filterData = (data, term) => {
    return data?.filter(
      (item) =>
        item.title.toLowerCase().includes(term.toLowerCase()) ||
        item.agent.name.toLowerCase().includes(term.toLowerCase()) ||
        item.owner.toLowerCase().includes(term.toLowerCase()) ||
        item.type.toLowerCase().includes(term.toLowerCase()) ||
        item.country.toLowerCase().includes(term.toLowerCase()) ||
        item.city.toLowerCase().includes(term.toLowerCase()) ||
        item.totalPrice.includes(term)
    );
  };

  // Get the filtered data based on the search term
  const filteredProperties = filterData(properties, searchTerm);

  // Determine which set of properties to use based on the search term
  const displayData = searchTerm ? filteredProperties : properties;

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setWidth(e.target.innerWidth);
    });
  }, []);

  return (
    <div id="compare-page">
      <header>
        <img src="./images/compare-header.webp" alt="" />
      </header>

      <div className="container">
        <main>
          <section>
            <h1 className="title">Compare Properties</h1>
            <div className="wrapper">
              {width > 767
                ? propsForCompare?.map((property) => (
                    <ComparePropertyCard
                      {...property}
                      propertiesID={propertiesID}
                      triggerRerender={triggerRerender}
                      setOpen={setOpen}
                      key={property.id}
                    />
                  ))
                : propsForCompare
                    ?.slice(0, 2)
                    .map((property) => (
                      <ComparePropertyCard
                        {...property}
                        propertiesID={propertiesID}
                        triggerRerender={triggerRerender}
                        setOpen={setOpen}
                        key={property.id}
                      />
                    ))}
              {width > 767
                ? propsForCompare?.length < 3 && (
                    <div
                      className="addNewProperty"
                      onClick={() => setOpen(true)}
                    >
                      <svg
                        width="117"
                        height="117"
                        viewBox="0 0 117 117"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="58.5"
                          cy="58.5"
                          r="58.5"
                          fill="#666666"
                          fillOpacity="0.34"
                        />
                        <path
                          d="M77.625 52.125H64.875V39.375C64.875 37.6842 64.2033 36.0627 63.0078 34.8672C61.8123 33.6716 60.1908 33 58.5 33C56.8092 33 55.1877 33.6716 53.9922 34.8672C52.7967 36.0627 52.125 37.6842 52.125 39.375L52.3513 52.125H39.375C37.6842 52.125 36.0627 52.7967 34.8672 53.9922C33.6716 55.1877 33 56.8092 33 58.5C33 60.1908 33.6716 61.8123 34.8672 63.0078C36.0627 64.2033 37.6842 64.875 39.375 64.875L52.3513 64.6487L52.125 77.625C52.125 79.3158 52.7967 80.9373 53.9922 82.1328C55.1877 83.3284 56.8092 84 58.5 84C60.1908 84 61.8123 83.3284 63.0078 82.1328C64.2033 80.9373 64.875 79.3158 64.875 77.625V64.6487L77.625 64.875C79.3158 64.875 80.9373 64.2033 82.1328 63.0078C83.3284 61.8123 84 60.1908 84 58.5C84 56.8092 83.3284 55.1877 82.1328 53.9922C80.9373 52.7967 79.3158 52.125 77.625 52.125Z"
                          fill="#666666"
                        />
                      </svg>
                      Add New Property
                    </div>
                  )
                : propsForCompare?.length < 2 && (
                    <div
                      className="addNewProperty"
                      onClick={() => setOpen(true)}
                    >
                      <svg
                        width="117"
                        height="117"
                        viewBox="0 0 117 117"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="58.5"
                          cy="58.5"
                          r="58.5"
                          fill="#666666"
                          fillOpacity="0.34"
                        />
                        <path
                          d="M77.625 52.125H64.875V39.375C64.875 37.6842 64.2033 36.0627 63.0078 34.8672C61.8123 33.6716 60.1908 33 58.5 33C56.8092 33 55.1877 33.6716 53.9922 34.8672C52.7967 36.0627 52.125 37.6842 52.125 39.375L52.3513 52.125H39.375C37.6842 52.125 36.0627 52.7967 34.8672 53.9922C33.6716 55.1877 33 56.8092 33 58.5C33 60.1908 33.6716 61.8123 34.8672 63.0078C36.0627 64.2033 37.6842 64.875 39.375 64.875L52.3513 64.6487L52.125 77.625C52.125 79.3158 52.7967 80.9373 53.9922 82.1328C55.1877 83.3284 56.8092 84 58.5 84C60.1908 84 61.8123 83.3284 63.0078 82.1328C64.2033 80.9373 64.875 79.3158 64.875 77.625V64.6487L77.625 64.875C79.3158 64.875 80.9373 64.2033 82.1328 63.0078C83.3284 61.8123 84 60.1908 84 58.5C84 56.8092 83.3284 55.1877 82.1328 53.9922C80.9373 52.7967 79.3158 52.125 77.625 52.125Z"
                          fill="#666666"
                        />
                      </svg>
                      Add New Property
                    </div>
                  )}
            </div>
          </section>

          <section>
            <h2 className="title">Home Features</h2>
            <div className="wrapper">
              {width > 767
                ? propsForCompare?.map((property) => (
                    <CompareFeatureCard {...property} key={property.id} />
                  ))
                : propsForCompare
                    ?.slice(0, 2)
                    .map((property) => (
                      <CompareFeatureCard {...property} key={property.id} />
                    ))}
              {width > 767
                ? propsForCompare?.length < 3 && (
                    <div className="emptyCard"></div>
                  )
                : propsForCompare?.length < 2 && (
                    <div className="emptyCard"></div>
                  )}
            </div>
          </section>

          <section>
            <h2 className="title">Validated Information</h2>
            <div className="wrapper">
              {width > 767
                ? propsForCompare?.map((property) => (
                    <CompareInformationCard {...property} key={property.id} />
                  ))
                : propsForCompare
                    ?.slice(0, 2)
                    .map((property) => (
                      <CompareInformationCard {...property} key={property.id} />
                    ))}
              {width > 767
                ? propsForCompare?.length < 3 && (
                    <div className="emptyCard"></div>
                  )
                : propsForCompare?.length < 2 && (
                    <div className="emptyCard"></div>
                  )}
            </div>
          </section>

          <section>
            <h2 className="title">Amenities</h2>
            <div className="wrapper">
              {width > 767
                ? propsForCompare?.map((property) => (
                    <CompareAmenitiesCard {...property} key={property.id} />
                  ))
                : propsForCompare
                    ?.slice(0, 2)
                    .map((property) => (
                      <CompareAmenitiesCard {...property} key={property.id} />
                    ))}
              {width > 767
                ? propsForCompare?.length < 3 && (
                    <div className="emptyCard"></div>
                  )
                : propsForCompare?.length < 2 && (
                    <div className="emptyCard"></div>
                  )}
            </div>
          </section>
        </main>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal">
          <h2 className="modal__title">Choose property to compare</h2>
          <div className="searchbar">
            <FiSearch />
            <input
              type="text"
              name="searchForCompare"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="search by enter the property title"
            />
          </div>
          <div className="search__container">
            {displayData?.map((property) => (
              <PropertyCard {...property} key={property.id} />
            ))}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Compare;

import React from "react";
import "./Interested.scss";
import { useParams } from "react-router-dom";
import {
  useGetAllFavRentByUIDQuery,
  useGetAllFavSaleByUIDQuery,
} from "../../redux/services/favoriteApiSlice";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper/modules";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import { useSelector } from "react-redux";

const Interested = () => {
  const { user } = useSelector((state) => state.user);
  const { userID } = useParams();
  const { data: favoriteSales } = useGetAllFavSaleByUIDQuery(userID);
  // const { data: favoriteRents } = useGetAllFavRentByUIDQuery(userID);

  return (
    <section className="interested-page">
      <header>
        <div className="container">
          <h1 className="username">Hi {user?.username}</h1>
          <h2 className="email">Email: {user?.email}</h2>
          <div className="btns-wrapper">
            {/* <button href="#" className="btn btn-filled">
              Change your password
            </button> */}
            {/* <Link
              to="/"
              className="btn btn-outlined"
              onClick={() => {
                cookies.remove("user_info");
              }}
            >
              Logout
            </Link> */}
          </div>
        </div>
      </header>

      <main className="container">
        <h2 className="favorite-title">Favorite List</h2>
        <p>
          Continue shopping the properties you’ve previously saved. Share them
          with friends, family, and even Deja Vu Reve Specialist to get help
          finding what’s right for you.
        </p>
        <div className="favorites-container">
          {!!favoriteSales?.length ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={40}
              loop={true}
              scrollbar={{
                hide: false,
              }}
              modules={[Autoplay, Scrollbar]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  // spaceBetween: 120,
                },
                768: {
                  slidesPerView: 3,
                  // spaceBetween: 300,
                },
              }}
            >
              {favoriteSales.map((property) => (
                <SwiperSlide key={property.id}>
                  <PropertyCard {...property?.saleUnit} userId={user.id} />
                </SwiperSlide>
              ))}
              {/* {favoriteRents?.map((property) => (
                <SwiperSlide key={property.id}>
                  <PropertyCard {...property?.rentUnit} userId={user.id} />
                </SwiperSlide>
              ))} */}
            </Swiper>
          ) : (
            <p className="no-favorite">
              There is no property in your favorite list to show!
            </p>
          )}
        </div>
      </main>
    </section>
  );
};

export default Interested;

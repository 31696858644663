import React from "react";
import "./CompareInformationCard.scss";

const CompareInformationCard = ({
  bluePrint,
  images,
  country,
  city,
  owner,
  floor,
  unitNo,
  totalPrice,
}) => {
  return (
    <div className="compareInformationCard">
      <img src={bluePrint || images[1]?.url} alt="" />
      <div className="information-wrapper">
        <div className="information">
          <div className="title">Country</div>
          <div className="value">{country}</div>
        </div>
        <div className="information">
          <div className="title">City</div>
          <div className="value">{city}</div>
        </div>
        <div className="information">
          <div className="title">Owner</div>
          <div className="value">{owner}</div>
        </div>
        <div className="information">
          <div className="title">Floor</div>
          <div className="value">{floor}</div>
        </div>
        <div className="information">
          <div className="title">Unit Number</div>
          <div className="value">{unitNo}</div>
        </div>
        {!!totalPrice && (
          <div className="information">
            <div className="title">Total Price</div>
            <div className="value">
              {Number(totalPrice).toLocaleString()} AED
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompareInformationCard;

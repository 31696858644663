import React from "react";
import { AiFillCar } from "react-icons/ai";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { GiBathtub, GiResize } from "react-icons/gi";
import { CiLocationOn } from "react-icons/ci";

import "./CompareFeatureCard.scss";

const CompareFeatureCard = ({
  bedrooms,
  bathrooms,
  area,
  parkingCount,
  location,
}) => {
  return (
    <div className="compareFeatureCard">
      <div className="features-container">
        <div className="feature">
          <BedOutlinedIcon />
          {bedrooms} Bedrooms
        </div>
        <div className="feature">
          <GiBathtub />
          {bathrooms} Bathroom
        </div>
        <div className="feature">
          <GiResize />
          {area} sqft
        </div>
        <div className="feature">
          <AiFillCar />
          {parkingCount} Parking
        </div>
        <div className="feature">
          <CiLocationOn />
          {location}
        </div>
      </div>
    </div>
  );
};

export default CompareFeatureCard;

import React from "react";
import "./WhyChooseUs.scss";
import BasicAccordion from "./Accordion";
const WhyChooseUs = () => {
   return (
      <>
         <section id="why-choose">
            <div className="why-choose_title">
               <p>Why Choose Us</p>
            </div>

            <div className="select-us_img"></div>

            <div className="accordion">
               <BasicAccordion></BasicAccordion>
            </div>
         </section>
      </>
   );
};

export default WhyChooseUs;

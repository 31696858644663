import React from 'react';
import './ContactUs.scss'
import { Link } from 'react-router-dom';

const ContactUs = () => {
  return (
    <section className='contact-us-section'>
      <div className="container">
         <div className="center">
            <h2 className='contact-us-title'>Have a Dream Project</h2>
            <p className='contact-us-description'>
               Let’s talk to us there your dream project and
               we will transform it to reality
            </p>
            <Link to="/contact-us" className='contact-us-btn'>Contact Us</Link>
         </div>
      </div>
    </section>
  )
}

export default ContactUs;
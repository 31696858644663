import React, { useState, useEffect } from "react";
import "./SearchFilters.scss";
import SearchedPropertyCard from "../../components/SearchedPropertyCard/SearchedPropertyCard";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { PiSortAscendingBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import SearchForm from "../../components/SearchForm/SearchForm";
import {
  fetchFilteredProperties,
  resetSearchQuery,
} from "../../redux/searchSlice";
import Grid from "@mui/material/Grid";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import { useGetPropertiesQuery } from "../../redux/services/salePropsApiSlice";
import useIntersectionObserver from "../../hook/useIntersectionObserver";
import swal from "sweetalert";

const SearchFilters = () => {
  let initState;
  const [filterBoxOpen, setFilterBoxOpen] = useState(initState);
  const filterBtnHandler = () => setFilterBoxOpen(!filterBoxOpen);
  const [sortBy, setSortBy] = useState("newest");
  const [sortType, setSortType] = useState("ascending");

  const dispatch = useDispatch();
  const { searchQuery, filteredProperties, error } = useSelector(
    (state) => state.search
  );

  useEffect(() => {
    if (error === "Request failed with status code 404") {
      swal({
        icon: "error",
        title: "Not Found",
        text: "Such a property is not currently available",
        timer: 3000,
      }).then((res) => {
        dispatch(resetSearchQuery());
      });
    }
  }, [error, dispatch]);

  const [totalItems, setTotalItems] = useState(15);
  const { data, isLoading, isFetching } = useGetPropertiesQuery({
    take: totalItems,
  });

  useEffect(() => {
    if (window.innerWidth > 768) {
      initState = true;
    } else {
      initState = false;
    }

    dispatch(fetchFilteredProperties(searchQuery));
  }, []);

  const { ref, isIntersecting } = useIntersectionObserver({ threshold: 0.5 });

  // Do something when the observed element intersects or not
  useEffect(() => {
    if (isIntersecting) {
      console.log("Element is intersecting!");
      // Perform actions when the observed element is intersecting
      setTotalItems((prevTotal) => prevTotal + 20);
    }
  }, [isIntersecting]);

  const searchHandler = () => {
    dispatch(fetchFilteredProperties(searchQuery));
    setFilterBoxOpen(!filterBoxOpen);
  };

  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    filteredProperties?.length > 0
      ? setDisplayData(filteredProperties)
      : setDisplayData(data?.properties);
  }, [filteredProperties, data]);

  useEffect(() => {
    switch (sortBy) {
      case "bedrooms":
        setDisplayData((prevData) => {
          if (sortType === "ascending") {
            return [...prevData]?.sort((a, b) => a.bedrooms - b.bedrooms);
          } else {
            return [...prevData]?.sort((a, b) => b.bedrooms - a.bedrooms);
          }
        });
        break;

      case "price":
        setDisplayData((prevData) => {
          if (sortType === "ascending") {
            return [...prevData]?.sort((a, b) => a.totalPrice - b.totalPrice);
          } else {
            return [...prevData]?.sort((a, b) => b.totalPrice - a.totalPrice);
          }
        });
        break;

      case "bathrooms":
        setDisplayData((prevData) => {
          if (sortType === "ascending") {
            return [...prevData]?.sort((a, b) => a.bathrooms - b.bathrooms);
          } else {
            return [...prevData]?.sort((a, b) => b.bathrooms - a.bathrooms);
          }
        });
        break;

      case "area":
        setDisplayData((prevData) => {
          if (sortType === "ascending") {
            return [...prevData]?.sort((a, b) => a.area - b.area);
          } else {
            return [...prevData]?.sort((a, b) => b.area - a.area);
          }
        });
        break;

      case "newest":
        if (sortType === "ascending") {
          // Determine which set of properties to use based on the search term
          filteredProperties?.length > 0
            ? setDisplayData(filteredProperties)
            : setDisplayData(data?.properties);
        } else {
          filteredProperties?.length > 0
            ? setDisplayData(filteredProperties.toReversed())
            : setDisplayData(data?.properties.toReversed());
        }
        break;

      default:
        break;
    }
  }, [sortBy, sortType, filteredProperties, data]);

  return (
    <div id="search-page">
      <div className="container-fluid">
        <header id="search-header">
          <img src="./images/page-search-header.jpg" alt="" />
        </header>
        <div className="filter-page-container">
          <main id="search-main-section">
            <section
              className={`filter-box ${
                filterBoxOpen ? "" : " filter-box-close"
              }`}
            >
              <SearchForm
                searchHandler={searchHandler}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortType={sortType}
                setSortType={setSortType}
              />
            </section>
            <section className="result-box">
              <div className="result-title-icon-wrapper">
                <div className="result-box">
                  <span className="result-number">
                    {filteredProperties?.length
                      ? filteredProperties?.length
                      : data?.properties?.length}
                  </span>{" "}
                  Results
                </div>
                <div className="icons-box">
                  <div className="filters-icon-box" onClick={filterBtnHandler}>
                    {/* <i className="filters-icon"></i> */}
                    <img
                      src="./images/filter-icon.svg"
                      alt=""
                      className="filters-icon"
                    />
                    <span className="filters-title">Filters</span>
                  </div>
                  {/* <div className="sort-icon-box">
                    <i className="sort-icon"><PiSortAscendingBold/></i>
                    <img
                      src="./images/sort-icon.svg"
                      alt=""
                      className="sort-icon"
                    />
                    <span className="sort-title">Sort</span>
                  </div> */}
                </div>
              </div>

              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ px: 5, py: 2 }}
              >
                {displayData?.map((property) => (
                  <Grid item xs={4} sm={4} md={4} key={property.id}>
                    <PropertyCard {...property} />
                  </Grid>
                ))}
              </Grid>

              {/* Render loading indicator */}
              {(isLoading || isFetching) && <p>Loading...</p>}

              {displayData?.length >= 20 && (
                <button className="show-more-btn" ref={ref}>
                  View more
                  <i className="arrow-icon">
                    <LiaLongArrowAltRightSolid />
                  </i>
                </button>
              )}
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;

import { apiSlice } from "../api/apiSlice";

const favoriteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllFavSaleByUID: builder.query({
      query: (userId) => `/fav/${userId}/fav-sales`,
      providesTags: ["Fav-sales"],
    }),

    getAllFavRentByUID: builder.query({
      query: (userId) => `/fav/${userId}/fav-rents`,
      providesTags: ["Fav-rents"],
    }),

    addNewFavSale: builder.mutation({
      query: ({ userId, saleId }) => ({
        url: `/fav/${userId}/fav-sales`,
        method: "POST",
        body: { saleId },
      }),
      invalidatesTags: ["Fav-sales"],
    }),

    addNewFavRent: builder.mutation({
      query: ({ userId, rentId }) => ({
        url: `/fav/${userId}/fav-rents`,
        method: "POST",
        body: { rentId },
      }),
      invalidatesTags: ["Fav-rents"],
    }),

    removeFavSale: builder.mutation({
      query: ({ userId, saleId }) => ({
        url: `/fav/${userId}/fav-sales`,
        method: "DELETE",
        body: { saleId },
      }),
      invalidatesTags: ["Fav-sales"],
    }),

    removeFavRent: builder.mutation({
      query: ({ userId, rentId }) => ({
        url: `/fav/${userId}/fav-rents`,
        method: "DELETE",
        body: { rentId },
      }),
      invalidatesTags: ["Fav-rents"],
    }),
  }),
});

export const {
  useGetAllFavSaleByUIDQuery,
  useGetAllFavRentByUIDQuery,
  useAddNewFavSaleMutation,
  useAddNewFavRentMutation,
  useRemoveFavSaleMutation,
  useRemoveFavRentMutation,
} = favoriteApiSlice;

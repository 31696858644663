import React, { useEffect, useRef } from "react";
import "./Signup.scss";
import { Controller, useForm } from "react-hook-form";
import { Button, FormControl, TextField } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRegisterUserMutation } from "../../redux/services/usersApiSlice";
import { setUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Signup = ({ setOpen, setIsLoginFromActive }) => {
  const dispatch = useDispatch();
  const phoneRef = useRef();

  const [registerUser, { data: user, isSuccess }] = useRegisterUserMutation();

  const schema = yup.object({
    username: yup.string().min(3).max(40).required(),
    email: yup.string().email().required(),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    phone: yup.string(),
    // phone: yup.string().matches(/^\d{10,}$/, "Phone number must be 10 digits"),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },
    resolver: yupResolver(schema),
  });

  const signUpHandler = (data) => {
    const { username, email, password, phone } = data;
    registerUser({
      username,
      email,
      password,
      phone,
    });
  };

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
      setOpen(false);
    }
  }, [isSuccess, user, dispatch, setOpen]);

  return (
    <form action="" onSubmit={handleSubmit(signUpHandler)}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Username"
          variant="outlined"
          {...register("username")}
          placeholder="Enter username"
          error={errors?.username}
          helperText={errors?.username?.message}
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Email"
          variant="outlined"
          {...register("email")}
          placeholder="Enter email"
          error={errors?.email}
          helperText={errors?.email?.message}
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Password"
          variant="outlined"
          {...register("password")}
          placeholder="Enter password"
          error={errors?.password}
          helperText={errors?.password?.message}
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Confirm Password"
          variant="outlined"
          {...register("confirmPassword")}
          placeholder="Enter confirmPassword"
          error={errors?.confirmPassword}
          helperText={errors?.confirmPassword?.message}
        />
      </FormControl>
      <FormControl
        fullWidth
        sx={{ border: "1px solid #ccc", borderRadius: ".3rem" }}
      >
        <label
          htmlFor="phone"
          style={{ padding: "1rem 2rem" }}
          onClick={(e) => {
            e.target.hasAttribute("for") && phoneRef.current.focus();
          }}
        >
          <Controller
            render={({ field }) => (
              <PhoneInput
                {...field}
                placeholder="(555) 123-4567"
                defaultCountry="AE"
                ref={phoneRef}
              />
            )}
            name="phone"
            control={control}
            defaultValue=""
          />
        </label>
      </FormControl>
      {errors?.phone && <p style={{ color: "red" }}>{errors.phone.message}</p>}

      <Button
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        sx={{ mt: 2 }}
      >
        Register
      </Button>
      <p
        style={{ paddingTop: "1rem", textAlign: "center", fontWeight: "bold" }}
      >
        Already have an account?{" "}
        <span
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => setIsLoginFromActive(true)}
        >
          Login Now
        </span>
      </p>
    </form>
  );
};

export default Signup;

import React from "react";
import "./ForYou.scss";
import ArticleCard from "../ArticleCard/ArticleCard";
import { useGetAllArticlesQuery } from "../../redux/services/articlesApiSlice";
import { Link } from "react-router-dom";

const ForYou = ({ articleID }) => {
  const { data: articles, isSuccess } = useGetAllArticlesQuery();

  return (
    <section id="forYou-section">
      <h2 id="forYou-title">For You</h2>
      <div className="article-card-wrapper">
        {isSuccess &&
          articles
            ?.filter((article) => article.id !== articleID)
            ?.slice(0, 3)
            ?.map((article) => (
              <Link
                to={`/blog/${article.id}`}
                className="article-card"
                key={article.id}
              >
                <ArticleCard {...article} />
              </Link>
            ))}
      </div>
    </section>
  );
};

export default ForYou;

import React from "react";
import "./Header.scss";

import SearchBox from "../SearchBox/SearchBox";
import Typewriter from "typewriter-effect";

const Header = () => {
  return (
    <header id="main-header">
      <div className="container">
        <h1 className="logan">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString("Home is where <br/> your story begins.")
                .start()
                .pauseFor(2500)
                .deleteAll()
                .typeString("Finding Your <br/> New Home Is Simple.")
                .start()
                .pauseFor(2500)
                .deleteAll();
            }}
            options={{
              autoStart: true,
              loop: true,
            }}
          />
        </h1>

        <SearchBox />
      </div>
    </header>
  );
};

export default Header;

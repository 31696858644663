import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./Gallery.scss";

// import required modules
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";

export default function Gallery({ images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <div className="gallery">
        {!!images?.length && (
          <>
            <Swiper
              className="mySwiper2"
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              lazy={true}
            >
              {images.map((image) => (
                <SwiperSlide key={image.id}>
                  <img
                    src={image?.url}
                    alt={`id: ${image.id}`}
                    loading="lazy"
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              className="mySwiper"
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={3}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              breakpoints={{
                640: {
                  slidesPerView: 4,
                },
              }}
              lazy="true"
            >
              {images.map((image) => (
                <SwiperSlide key={image.id}>
                  <img
                    src={image?.url}
                    alt={`id: ${image.id}`}
                    loading="lazy"
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div>
    </>
  );
}

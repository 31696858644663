import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "https://server.dejavureve.com/api" }),
  tagTypes: [
    "Admins",
    "Articles",
    "Projects",
    "SaleProperties",
    "RentProperties",
    "Agents",
    "Amenities",
    "Categories",
    "Types",
    "Views",
  ],
  // eslint-disable-next-line no-unused-vars
  endpoints: (builder) => ({}),
});

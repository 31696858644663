import React, { useEffect } from "react";
import "./Login.scss";
import { useForm } from "react-hook-form";
import { Button, FormControl, TextField } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginUserMutation } from "../../redux/services/usersApiSlice";
import { setUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

const Login = ({ setOpen, setIsLoginFromActive }) => {
  const dispatch = useDispatch();

  const [loginUser, { data: user, isSuccess }] = useLoginUserMutation();

  const schema = yup.object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const loginHandler = (data) => {
    const { email, password } = data;
    loginUser({
      email,
      password,
    });
  };

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
      setOpen(false);
    }
  }, [isSuccess, user, dispatch, setOpen]);

  return (
    <form action="" onSubmit={handleSubmit(loginHandler)}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Email"
          variant="outlined"
          {...register("email")}
          placeholder="Enter email"
          error={errors?.email}
          helperText={errors?.email?.message}
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Password"
          variant="outlined"
          {...register("password")}
          placeholder="Enter password"
          error={errors?.password}
          helperText={errors?.password?.message}
        />
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        sx={{ mt: 2 }}
      >
        Login
      </Button>
      <p
        style={{ paddingTop: "1rem", textAlign: "center", fontWeight: "bold" }}
      >
        Not a Member Yet?{" "}
        <span
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => setIsLoginFromActive(false)}
        >
          Register Now
        </span>
      </p>
    </form>
  );
};

export default Login;

import React from "react";
import "./CompareAmenitiesCard.scss";

const CompareAmenitiesCard = ({ amenities }) => {
  return (
    <div className="compareAmenitiesCard">
      <ul className="amenities__list">
        {amenities.map((amenity) => (
          <li key={amenity}>{amenity}</li>
        ))}
      </ul>
    </div>
  );
};

export default CompareAmenitiesCard;

import React, { useState } from "react";
import "./CallToAction.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";

const CallToAction = ({ agent, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={isOpen ? "callToAction callToAction--open" : "callToAction"}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="shape">
        <ArrowBackIosNewIcon className="arrow" />
        <div className="wrapper">
          <div className="agent">
            <img src={agent?.imageUrl} alt={agent?.name} />
          </div>
          <div className="icon">
            <a href={`tel:${agent?.phone}`}>
              <CallIcon />
            </a>
            <span>Call</span>
          </div>
          <div className="icon">
            <a
              href={`https://api.whatsapp.com/send/?phone=${agent?.whatsApp}&text=I am interested to know more about this property: ${title}`}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon />
            </a>
            <span>WhatsApp</span>
          </div>
          <div className="icon">
            <a href={`mailto:${agent?.email}`}>
              <MailOutlineIcon />
            </a>
            <span>Mail</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;

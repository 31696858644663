import React, { useState } from "react";
import "./Navbar.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/userSlice";
import { Box, Modal } from "@mui/material";
import Form from "../Form/Form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "2rem",
  boxShadow: 24,
  p: 4,
};

const Navbar = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const hamburgerBtnHandler = () => setHamburgerOpen(!hamburgerOpen);

  const { user, isLogin } = useSelector((state) => state.user);

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="navbar__items">
            <NavLink to="/" className="logo-box">
              <img
                src="/images/Orginal-logo.png"
                alt="Deja Vu Reve"
                className="logo-box__img"
              />
            </NavLink>

            <ul className="menu">
              <li className="menu-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "menu-item__link menu-item__link--active"
                      : "menu-item__link"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="/projects"
                  className={({ isActive }) =>
                    isActive
                      ? "menu-item__link menu-item__link--active"
                      : "menu-item__link"
                  }
                >
                  Projects
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="/search"
                  className={({ isActive }) =>
                    isActive
                      ? "menu-item__link menu-item__link--active"
                      : "menu-item__link"
                  }
                >
                  Properties
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive
                      ? "menu-item__link menu-item__link--active"
                      : "menu-item__link"
                  }
                >
                  About Us
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="/contact-us"
                  className={({ isActive }) =>
                    isActive
                      ? "menu-item__link menu-item__link--active"
                      : "menu-item__link"
                  }
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="/blogs"
                  className={({ isActive }) =>
                    isActive
                      ? "menu-item__link menu-item__link--active"
                      : "menu-item__link"
                  }
                >
                  Blogs
                </NavLink>
              </li>
            </ul>

            <div
              className={`mobile-menu-wrapper ${
                hamburgerOpen ? "" : " mobile-menu-wrapper--close"
              }`}
            >
              <ul className="mobile-menu-list">
                <li className="mobile-menu-item">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "mobile-menu-item__link mobile-menu-item__link--active"
                        : "mobile-menu-item__link"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li className="mobile-menu-item">
                  <NavLink
                    to="/projects"
                    className={({ isActive }) =>
                      isActive
                        ? "mobile-menu-item__link mobile-menu-item__link--active"
                        : "mobile-menu-item__link"
                    }
                  >
                    Projects
                  </NavLink>
                </li>
                <li className="mobile-menu-item">
                  <NavLink
                    to="/search"
                    className={({ isActive }) =>
                      isActive
                        ? "mobile-menu-item__link mobile-menu-item__link--active"
                        : "mobile-menu-item__link"
                    }
                  >
                    Properties
                  </NavLink>
                </li>

                <li className="mobile-menu-item">
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive
                        ? "mobile-menu-item__link mobile-menu-item__link--active"
                        : "mobile-menu-item__link"
                    }
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="mobile-menu-item">
                  <NavLink
                    to="/contact-us"
                    className={({ isActive }) =>
                      isActive
                        ? "mobile-menu-item__link mobile-menu-item__link--active"
                        : "mobile-menu-item__link"
                    }
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="mobile-menu-item">
                  <NavLink
                    to="/blogs"
                    className={({ isActive }) =>
                      isActive
                        ? "mobile-menu-item__link mobile-menu-item__link--active"
                        : "mobile-menu-item__link"
                    }
                  >
                    Blogs
                  </NavLink>
                </li>
              </ul>

              <div className="mobile-menu__btn-links">
                {isLogin ? (
                  <div className="user_profile_mobile">
                    <img src="/images/profile.svg" alt={user?.name} />
                    <NavLink
                      to={`/interest/${user?.id}`}
                      className="mobile-menu__btn-link"
                    >
                      Favorite List
                    </NavLink>
                    <NavLink
                      to="/"
                      className="mobile-menu__btn-link"
                      onClick={() => {
                        dispatch(removeUser());
                      }}
                    >
                      Log Out
                    </NavLink>
                  </div>
                ) : (
                  <button
                    className="mobile-menu__btn-link"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Login
                  </button>
                )}
              </div>
            </div>

            <div className="navbar__btn-links">
              {isLogin ? (
                <div className="user__profile">
                  <img src="/images/profile.svg" alt={user?.name} />
                  <div className="dropdown">
                    <NavLink to={`/interest/${user?.id}`}>
                      Favorite List
                    </NavLink>
                    <NavLink
                      to="/"
                      className="logout"
                      onClick={() => {
                        dispatch(removeUser());
                      }}
                    >
                      Log Out
                    </NavLink>
                  </div>
                </div>
              ) : (
                <button
                  className="navbar__btn-link navbar__btn-link--border"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Login
                </button>
              )}
            </div>

            <div
              className={`nav__hamburger-btn ${
                hamburgerOpen ? " nav__hamburger-btn--open" : ""
              }`}
              onClick={hamburgerBtnHandler}
            >
              <span className="nav__hamburger-btn-line"></span>
            </div>
          </div>
        </div>
      </nav>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Form setOpen={setOpen} />
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;

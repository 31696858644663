import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/" });

const initialState = cookies.get("user_info")
  ? { user: cookies.get("user_info"), isLogin: true }
  : { user: {}, isLogin: false };

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;
      cookies.set("user_info", action.payload, { maxAge: 172800 });
    },
    removeUser: (state, action) => {
      state.user = {};
      state.isLogin = false;
      cookies.remove("user_info");
    },
  },
});

export default userSlice.reducer;
export const { setUser, removeUser } = userSlice.actions;

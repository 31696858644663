import { apiSlice } from "../api/apiSlice";

export const messagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendMessages: builder.mutation({
      query: (message) => ({
        url: `/messages`,
        method: "POST",
        body: message,
      }),
    }),
  }),
});

export const { useSendMessagesMutation } = messagesApiSlice;

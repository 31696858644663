import React from "react";
import "./Blog.scss";
import Author from "../../components/Author/Author";
import ForYou from "../../components/ForYou/ForYou";
import { useParams } from "react-router-dom";
import { useGetArticleQuery } from "../../redux/services/articlesApiSlice";
import DOMPurify from "dompurify";
import { Box, Skeleton } from "@mui/material";
import Gallery from "../../components/Gallery/Gallery";

const Blog = () => {
  const { blogID } = useParams();
  const { data: article, isLoading } = useGetArticleQuery(blogID);

  return (
    <div id="blog-page">
      <div className="container-fluid">
        <div className="container">
          <header id="blog-header">
            <div className="img-wrapper">
              {isLoading ? (
                <Box sx={{ width: "100%", height: "400px" }}>
                  <Skeleton height="100%" />
                </Box>
              ) : article.images.length > 1 ? (
                <Gallery images={article?.images} />
              ) : (
                <img
                  src={article?.images[0]?.url}
                  alt="Dubai United Arab Emirates"
                />
              )}
            </div>
          </header>

          <div className="grid">
            {/* <div className="author-section">
              <Author />
            </div> */}
            <main id="blog-main">
              <section className="content-wrapper">
                {isLoading ? (
                  <Box sx={{ width: "100%" }}>
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                    <Skeleton sx={{ mb: 1 }} />
                  </Box>
                ) : (
                  <>
                    <h1>{article?.title}</h1>
                    <p>{article?.description}</p>
                    <div
                      className="article-body"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(article?.body),
                      }}
                    ></div>
                  </>
                )}
              </section>
            </main>

            <aside className="aside-for-you">
              <section id="for-you-section">
                <ForYou articleID={blogID} />
              </section>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;

import React, { useState } from "react";
import "./ComparePropertyCard.scss";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { MdChangeCircle } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import {
  useAddNewFavSaleMutation,
  useGetAllFavSaleByUIDQuery,
  useRemoveFavSaleMutation,
} from "../../redux/services/favoriteApiSlice";

const ComparePropertyCard = ({
  id,
  title,
  images,
  type,
  propertiesID,
  setOpen,
  triggerRerender,
}) => {
  const navigate = useNavigate();
  const { user, isLogin } = useSelector((state) => state.user);
  const { data: favoriteSales } = useGetAllFavSaleByUIDQuery(user?.id);
  const [addFavSale] = useAddNewFavSaleMutation();
  const [removeFavSale] = useRemoveFavSaleMutation();
  const [isFavorite, setIsFavorite] = useState(
    favoriteSales?.some((property) => property.saleId === id)
  );

  const toggleFavorite = (e) => {
    e.stopPropagation();
    if (!isLogin) {
      swal({
        title: "You are not login",
        text: "To adding this property to your favorite you need to sign in first.",
        icon: "warning",
        buttons: "ok",
        timer: 5000,
      });
      return false;
    }

    if (isFavorite) {
      removeFavSale({ userId: user.id, saleId: id });
    } else {
      addFavSale({ userId: user.id, saleId: id });
    }
    setIsFavorite(!isFavorite);
  };

  const removeProperty = (e) => {
    e.stopPropagation();
    window.localStorage.setItem(
      "propsForCompare",
      JSON.stringify(propertiesID.filter((propertyID) => propertyID !== id))
    );
    triggerRerender();
  };

  const changeProperty = (e) => {
    e.stopPropagation();
    removeProperty(e);
    setOpen(true);
  };

  return (
    <div
      className="comparePropertyCard"
      onClick={() => navigate(`/sale-property-details/${id}`)}
    >
      <img src={images[0]?.url} alt="" />
      <div className="property-type">{type}</div>
      <div className="property-title">{title}</div>
      <div className="actions">
        {isFavorite ? (
          <FaHeart title="remove from favorite" onClick={toggleFavorite} />
        ) : (
          <FaRegHeart title="add to favorite" onClick={toggleFavorite} />
        )}
        <MdChangeCircle onClick={changeProperty} />
        <AiTwotoneDelete onClick={removeProperty} />
      </div>
    </div>
  );
};

export default ComparePropertyCard;

import React from "react";
import "./PopularResidenceCard.scss";

const PopularResidenceCard = ({ title, description, images }) => {
  return (
    <div className="card-shadow">
      <div className="property-card">
        <div className="card-img-box">
          <img src={images[0]?.url} alt={title} />
        </div>
        <div className="property-details-wrapper">
          {/* <h3 className="property-price-wrapper">
            <span className="icon-dollar">
              <BiDollar />
            </span>
            <span className="property-price">{price.toLocaleString()}</span>
          </h3> */}
          <h3 className="property-name">{title}</h3>
          <p className="property-content">{description}</p>
          <button className="property-detail-btn">Detail</button>
        </div>
      </div>
    </div>
  );
};

export default PopularResidenceCard;

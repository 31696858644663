import React from "react";
import "./App.scss";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const App = () => {
  const router = useRoutes(routes);

  return (
    <>
      {router}
      <ScrollToTop />
    </>
  );
};

export default App;
